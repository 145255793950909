$theme-coclor: #EE4B22;
$white-color: #FFFFFF;
$label-color: #171A33;
$border-color: #E8E8E8;
$grey-color: #979598;
$blue-color:#3441D2;
$admin-color:#263673;
$pop-color:  #241A2E;
$red-color: #F00;
$purple-color: #C60C68;
$divider-color: #EAECF0;
$save-color: #0061FF;