@import '../../../../style/_variable.scss';

@font-face {
  font-family: 'Poppins-Regular';
  src:
    local('Poppins-Regular'),
    url('../../../../assets/font/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src:
    local('Poppins-SemiBold'),
    url('../../../../assets/font/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Poppins-Medium';
  src:
    local('Poppins-Medium'),
    url('../../../../assets/font/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Poppins-Light';
  src:
    local('Poppins-Light'),
    url('../../../../assets/font/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Poppins-Bold';
  src:
    local('Poppins-Bold'),
    url('../../../../assets/font/Poppins-Bold.ttf') format('truetype');
  font-weight: bold;
}

.tagcon {
  background-color: #fff4f1;
  border-left: 6px solid #ee4b22;
  border-radius: 6px;
  padding: 6px 18px;
  display: flex;
  align-items: center;
  column-gap: 6px;
  .circ {
    border-radius: 50%;
    width: 12px;
    height: 12px;
  }
  .txt {
    font-family: Poppins-Regular !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #404040;
  }
}

.donutchartcon {
  position: relative;
  width: 215px;
  height: 215px;
  .totalcon {
    position: absolute;
    top: 40%;
    left: 43%;
    .total {
      font-family: Poppins-Medium !important;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      /* identical to box height, or 24px */
      letter-spacing: 0.2px;

      color: #848484;
    }
    .value {
      font-family: Poppins-Bold !important;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 32px;
      /* or 100% */
      letter-spacing: -0.02em;

      color: #344051;
    }
  }
}
.donutchartcon-in {
  position: relative;
  width: 250px;
  height: 250px;
  .totalcon {
    position: absolute;
    top: 35%;
    left: 35%;
    .total {
      font-family: Poppins-Medium !important;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      /* identical to box height, or 24px */
      letter-spacing: 0.2px;

      color: #848484;
    }
    .value {
      font-family: Poppins-Bold !important;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 32px;
      /* or 100% */
      letter-spacing: -0.02em;

      color: #344051;
    }
  }
}
