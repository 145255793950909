@import '../../../style/_variable.scss';

@font-face {
  font-family: 'Poppins-Regular';
  src:
    local('Poppins-Regular'),
    url('../../../assets/font/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src:
    local('Poppins-SemiBold'),
    url('../../../assets/font/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Poppins-Medium';
  src:
    local('Poppins-Medium'),
    url('../../../assets/font/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Poppins-Light';
  src:
    local('Poppins-Light'),
    url('../../../assets/font/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Poppins-Bold';
  src:
    local('Poppins-Bold'),
    url('../../../assets/font/Poppins-Bold.ttf') format('truetype');
  font-weight: bold;
}

.commonFlex {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}
.headercon {
  padding: 32px 24px 16px 24px;
  justify-content: space-between;

  border-bottom: 1px solid #d0d5dd;
  // margin-bottom: 24px;
}

.welcome-text {
  flex-direction: column;
  row-gap: 8px;
  align-items: flex-start;
  .heading {
    font-family: Poppins-SemiBold !important;
    font-style: normal;
    font-size: 20px;
    line-height: 20px;
    /* identical to box height, or 100% */

    color: #344051;
  }
  .description {
    /* Text sm/Medium */
    font-family: Poppins-Medium !important;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: #637083;
  }
}
.right-container {
  column-gap: 16px;
  .filter-icon {
    cursor: pointer;
  }
  .slider {
    column-gap: 8px;
    padding: 4px;
    // min-width: 153px;
    min-height: 40px;
    background: #eaecf0;
    border-radius: 6px;
    .options {
      /* text */

      padding: 6px 10px;
      cursor: pointer;
      font-family: Poppins-Medium !important;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #475569;
    }
    .Activoptions {
      /* Segmented item */

      /* Auto layout */
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 6px;
      /* Basic/White */
      background: #ffffff;
      /* /shadow/base */
      box-shadow:
        0px 1px 3px rgba(0, 0, 0, 0.1),
        0px 1px 2px rgba(0, 0, 0, 0.06);
      border-radius: 6px;
      color: #0f172a;
    }
  }
}

.top-card-container {
  padding: 30px 12px 30px 12px;
  border-bottom: 1px solid #d0d5dd;
  margin-bottom: 32px;
  .card-container {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    gap: 20px 16px;
    perspective: 1000px;
    .cards {
      background-color: #ffffff;
      border-radius: 8px;
      padding: 11px 10px;
      flex-basis: 182px;
      height: 100px;
      display: flex;
      column-gap: 10px;
      transition: transform 1s cubic-bezier(0.45, 1.45, 0.8, 1);
      transform-style: preserve-3d;
      cursor: pointer;
      transform-origin: center;
      animation-timing-function: cubic-bezier(0.45, 1.45, 0.8, 1);
      animation-duration: 300ms;
      &:hover {
        transform: rotateY(360deg);
      }
      .image {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        background-color: #f4f7fe;
        display: flex;
        justify-content: center;
        align-content: center;
        padding: 9px;
        img {
          max-height: 30px;
        }
      }
      .text {
        margin-top: 5px;
        .heading {
          max-width: 112px;
          font-family: Poppins-Medium !important;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          /* or 133% */

          color: #637083;
        }
        .description {
          margin-top: 5px;
          font-family: Poppins-Bold !important;
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 24px;
          /* or 100% */
          letter-spacing: -0.02em;

          color: #344051;
        }
      }
    }
  }
}

.headingContainer {
  padding: 0px 25px 24px 24px;
  width: 100%;
  .heading {
    font-family: Poppins-SemiBold !important;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    /* identical to box height, or 100% */

    color: #344051;
  }
}
.calculationTable {
  padding: 0px 25px 24px 24px;
  display: flex;
  flex-flow: row wrap;
  gap: 22px;
  border-bottom: 1px solid #d0d5dd;
  margin-bottom: 32px;
  .card {
    padding: 16px 20px;
    flex-basis: 369px;
    min-height: 230px;

    box-shadow: 0px 8px 24px 0px #4545501a;
    background-color: #ffffff;
    header {
      display: flex;
      column-gap: 12px;
      .heading {
        font-family: Poppins-SemiBold !important;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        /* identical to box height, or 24px */
        letter-spacing: 0.2px;

        color: #344051;
      }
      .month {
        padding: 4px 11.5px;
        background: #fdede9;
        border-radius: 4px;

        font-family: Poppins-Medium !important;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        /* Gray 02 */
        color: #656575;
      }
      .report {
        &:hover {
          background-color: #ee4b22 !important;
          color: #fff !important;
        }

        margin-left: auto;
        /* view report */

        box-sizing: border-box;

        background: #ffffff;
        border: 1px solid #ee4b22;
        border-radius: 4px;

        padding: 4px 12px;
        /* Desktop/Body/Small/Small -> Medium */
        font-family: Poppins-Medium !important;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;

        color: #ee4b22;
        cursor: pointer;
      }
    }
    main {
      margin-top: 37px;
      .circleloadercon {
        margin-top: 48px;
      }
      .description {
        max-width: 252px;
        font-family: Poppins-Regular !important;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        /* or 114% */

        color: #344051;
      }
    }
    footer {
      margin-top: 37px;

      display: flex;
      justify-content: space-between;
      .billingvalue {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .heading {
          font-family: Poppins-Medium !important;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          /* identical to box height, or 133% */

          color: #637083;
        }
        .price {
          /* Header/H1 */
          // font-family: Poppins-Bold !important;
          font-style: normal;
          font-weight: 700;
          font-size: 28px;
          line-height: 40px;
          /* identical to box height, or 143% */

          color: #344051;
        }
      }
      .chart {
        .chartvalue {
          padding-right: 20px;
          text-align: right;
          color: #16c06e;
          .lossval {
            color: #ee4b22 !important;
          }
          .arrowic {
            font-size: 12px;
            font-family: Poppins-Bold !important;
            line-height: 28px;
          }
          .arrcon {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            column-gap: 8px;
          }
        }
        .fontStyl {
          font-family: Poppins-Medium !important;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 28px;
          /* identical to box height, or 175% */
          letter-spacing: -0.009em;
        }
      }
    }
  }
}

.productivitygraph {
  margin-bottom: 32px;
  padding: 0px 25px 24px 24px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  border-bottom: 1px solid #d0d5dd;

  .productivitycards {
    padding: 27px 24px 24px 26px;
    background-color: #ffffff;
    // width: 564px;
    min-height: 100%;
    border-radius: 8px;
    box-shadow: 0px 4px 10px 0px #4545501a;

    header {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      .title {
        font-family: Poppins-SemiBold !important;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        /* identical to box height, or 120% */

        /* Neutral/Black */
        color: #1f1f25;
      }
      .viewdetail {
        cursor: pointer;
        font-family: Poppins-Medium !important;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #ee4b22;
        /* view report */

        box-sizing: border-box;

        padding: 10px 12px;
        gap: 4px;
        border: 1px solid #ee4b22;
        border-radius: 4px;
      }
    }

    main {
      display: flex;
      flex-direction: row;
      column-gap: 63px;
      .circleloadercon {
        margin-top: 41px;
      }
      .circleloadercon2 {
        width: 224px;
        height: 224px;
        transform: scale(1.25);
      }
    }
  }
}

.circSvgTxt {
  font-family: Poppins-Medium !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #0a0a0a;
}
.labelcon {
  margin-top: 76px;
  margin-right: 30px;
  .labelwrap {
    width: 189px;
    .labelItem {
      margin-top: 11px;
      &:nth-child(1) {
        margin-top: 0px;
      }
    }
  }
}

.labelcon2 {
  margin-top: 121px;
}

.dailyactivitycon {
  margin-bottom: 32px;
  padding: 32px 54px 0px 24px;
  display: flex;
  border-bottom: 1px solid #d0d5dd;
  .leftcon {
    .childcon {
      margin-bottom: 32px;
      background-color: #ffffff;
      box-shadow: 0px 4px 10px 0px #4545501a;
      padding: 37px 34px 29px 32px;
      width: 100%;
      min-height: 645px;
      max-height: 100%;
      border-radius: 8px;
      header {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        .title {
          font-family: Poppins-SemiBold !important;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          /* identical to box height, or 120% */

          /* Neutral/Black */
          color: #1f1f25;
        }
        .viewdetail {
          cursor: pointer;

          /* item05 */

          box-sizing: border-box;
          background: #fff4f1;
          border-radius: 4px;
          padding: 6.5px 50.5px;

          font-family: Poppins-Medium !important;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 17px;
          /* identical to box height */

          color: #ee4b22;
        }
      }
      main {
        margin-top: 34px;
        width: 100%;
        // transform: translateX(-80px);
        .barChart {
          height: 320px;
          // width: 700px;
        }
      }
      footer {
        margin-top: 110px;
        .con {
          width: 100%;
          display: flex;
          flex-flow: row wrap;
          column-gap: 41px;
          row-gap: 19px;
          .item {
            flex-shrink: 0;
            display: flex;
            column-gap: 10.04px;
            .circle {
              /* Ellipse 159 */

              width: 12.62px;
              height: 12.62px;
              border-radius: 50%;
            }
            .txt {
              width: 150px;
              font-family: Poppins-Medium !important;
              font-style: normal;
              font-weight: 500;
              font-size: 13.6243px;
              line-height: 16px;
              color: #808080;
            }
          }
        }
      }
    }
    .borderdivider {
      border-bottom: 1px solid #d0d5dd;
      margin-bottom: 32px;
    }
    .childcon2 {
      margin-bottom: 32px;
      background-color: #ffffff;
      padding: 37px 34px 29px 32px;
      width: 100%;
      min-height: 360px;
      box-shadow: 0px 4px 10px rgba(69, 69, 80, 0.1);
      border-radius: 8px;

      header {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        .title {
          font-family: Poppins-SemiBold !important;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          /* identical to box height, or 120% */

          /* Neutral/Black */
          color: #1f1f25;
        }
        .viewdetail {
          cursor: pointer;
          font-family: Poppins-Medium !important;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: #ee4b22;
          /* view report */

          box-sizing: border-box;

          padding: 10px 12px;
          gap: 4px;
          border: 1px solid #ee4b22;
          border-radius: 4px;
        }
      }
      main {
        display: flex;
        // gap: 42px;
        .donutchartout {
          transform: translateX(-40px);
        }
        .labelcon {
          // max-height: 32px !important;
          width: 100%;
          margin-top: 28px;
          margin-right: 30px;
          display: flex;
          flex-flow: row wrap;
          gap: 11px 14px;
          .labelItem {
            flex-basis: 172px;
            margin-top: 11px;
          }
        }
      }
    }
  }
  .rightcon {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    box-shadow: 0px 4px 10px 0px rgba(69, 69, 80, 0.1019607843);
    padding: 37px 34px 29px 32px;
    width: 100%;
    margin-left: 20px;
    min-height: 97.6%;
    border-radius: 8px;
    .title {
      font-family: Poppins-SemiBold !important;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      /* identical to box height, or 120% */

      /* Neutral/Black */
      color: #1f1f25;
    }
    .view-all {
      font-size: 12px;
      color: #5f2ee5;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .calendar-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      background-color: #ffedeb;
      border-radius: 10px;
      color: #f06666;

      .month-year {
        font-size: 16px;
        font-weight: bold;
      }

      .navigation {
        display: flex;
        gap: 5px;
        button {
          border: none;
          background: none;
          color: #f06666;
          font-size: 14px;
          cursor: pointer;
        }
      }
    }

    .calendar-grid {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      margin-top: 10px;

      .day-label {
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        color: #333;
      }

      .day-cell {
        text-align: center;
        padding: 10px 0;
        cursor: pointer;
        border-radius: 50%;
        &:hover {
          background-color: #fa9473;
          color: white;
        }
      }

      .highlighted-day {
        background-color: #f06666;
        color: white;
      }
    }
  }

  .todo-container {
    background-color: #ffffff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);

    .todo-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .todo-title {
        font-size: 16px;
        font-weight: bold;
        color: #f06666;
      }
    }

    .todo-list {
      margin-top: 10px;

      .todo-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        background-color: #f2f2f2;
        border: 1px solid #e5e5e5;
        border-radius: 10px;
        margin-bottom: 10px;

        .event-title {
          font-size: 14px;
          color: #333;
        }

        .event-time {
          font-size: 12px;
          color: #999;
        }

        .status-indicator {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          &.green {
            background-color: green;
          }
          &.red {
            background-color: red;
          }
          &.yellow {
            background-color: yellow;
          }
          &.blue {
            background-color: blue;
          }
          &.purple {
            background-color: purple;
          }
        }
      }
    }
  }
}
.view-all {
  font-size: 12px;
  color: #5f2ee5;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.Interviewcon {
  margin-bottom: 32px;
  padding: 0px 54px 24px 24px;
  display: flex;
  border-bottom: 1px solid #d0d5dd;

  .leftcon {
    .childcon {
      margin-bottom: 32px;
      background-color: #ffffff;
      box-shadow: 0px 4px 10px 0px #4545501a;
      padding: 37px 34px 29px 32px;
      width: 100%;
      height: 645px;
      border-radius: 8px;
      header {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        .title {
          font-family: Poppins-SemiBold !important;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          /* identical to box height, or 120% */

          /* Neutral/Black */
          color: #1f1f25;
        }
        .viewdetail {
          cursor: pointer;

          /* item05 */

          box-sizing: border-box;
          background: #fff4f1;
          border-radius: 4px;
          padding: 6.5px 50.5px;

          font-family: Poppins-Medium !important;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 17px;
          /* identical to box height */

          color: #ee4b22;
        }
      }
      main {
        margin-top: 34px;
        width: 70%;
        transform: translateX(-80px);
        .barChart {
          height: 285px;
          width: 700px;
          overflow-y: auto;
          scrollbar-width: none;
          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
      footer {
        margin-top: 20px;
        .con {
          width: 100%;
          display: flex;
          flex-flow: row wrap;
          column-gap: 41px;
          row-gap: 19px;
          .item {
            flex-shrink: 0;
            display: flex;
            column-gap: 10.04px;
            .circle {
              /* Ellipse 159 */

              width: 12.62px;
              height: 12.62px;
              border-radius: 50%;
            }
            .txt {
              width: 150px;
              font-family: Poppins-Medium !important;
              font-style: normal;
              font-weight: 500;
              font-size: 13.6243px;
              line-height: 16px;
              color: #808080;
            }
          }
        }
      }
    }

    .childcon2 {
      margin-bottom: 32px;
      background-color: #ffffff;
      padding: 37px 34px 29px 32px;
      width: 100%;
      height: 100%;
      min-height: 600px;
      box-shadow: 0px 4px 10px rgba(69, 69, 80, 0.1);
      border-radius: 8px;

      header {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        .title {
          font-family: Poppins-SemiBold !important;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          /* identical to box height, or 120% */

          /* Neutral/Black */
          color: #1f1f25;
        }
        .viewdetail {
          cursor: pointer;
          font-family: Poppins-Medium !important;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: #ee4b22;
          /* view report */

          box-sizing: border-box;

          padding: 10px 12px;
          gap: 4px;
          border: 1px solid #ee4b22;
          border-radius: 4px;
        }
      }
      main {
        // display: flex;
        // gap: 42px;
        .donutchartout {
          transform: translateX(-40px);
        }
        .labelcon {
          max-height: 100% !important;
          width: 100%;
          margin-top: 0px;
          margin-right: 30px;
          // display: flex;
          // flex-flow: row wrap;
          // gap: 11px 14px;
          .labelItem {
            flex-basis: 172px;
            margin-top: 11px;
          }
        }
      }
    }
    .borderdivider {
      border-bottom: 1px solid #d0d5dd;
      margin-bottom: 32px;
    }
  }
  .rightcon {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 20px;
    height: 765px;
    border-radius: 8px;
    .title {
      font-family: Poppins-SemiBold !important;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      /* identical to box height, or 120% */

      /* Neutral/Black */
      color: #1f1f25;
    }

    .childcon2 {
      margin-bottom: 32px;
      background-color: #ffffff;
      padding: 37px 34px 29px 32px;
      width: 100%;
      height: 512px;
      min-height: 600px;
      box-shadow: 0px 4px 10px rgba(69, 69, 80, 0.1);
      border-radius: 8px;

      header {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        .title {
          font-family: Poppins-SemiBold !important;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          /* identical to box height, or 120% */

          /* Neutral/Black */
          color: #1f1f25;
        }
        .viewdetail {
          cursor: pointer;
          font-family: Poppins-Medium !important;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: #ee4b22;
          /* view report */

          box-sizing: border-box;

          padding: 10px 12px;
          gap: 4px;
          border: 1px solid #ee4b22;
          border-radius: 4px;
        }
      }
      main {
        // display: flex;
        // gap: 42px;
        .donutchartout {
          transform: translateX(-40px);
        }
        .labelcon {
          max-height: 32px !important;
          width: 100%;
          margin-top: 28px;
          margin-right: 30px;
          display: flex;
          flex-flow: row wrap;
          gap: 11px 14px;
          .labelItem {
            flex-basis: 172px;
            margin-top: 11px;
          }
        }
      }
    }
    .borderdivider {
      border-bottom: 1px solid #d0d5dd;
      margin-bottom: 32px;
    }
  }
}
.joineecon {
  margin-bottom: 32px;
  padding: 0px 54px 24px 24px;
  display: flex;
  border-bottom: 1px solid #d0d5dd;

  .leftcon {
    .childcon {
      margin-bottom: 32px;
      background-color: #ffffff;
      box-shadow: 0px 4px 10px 0px #4545501a;
      padding: 37px 34px 29px 32px;
      width: 100%;
      height: 645px;
      border-radius: 8px;
      header {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        .title {
          font-family: Poppins-SemiBold !important;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          /* identical to box height, or 120% */

          /* Neutral/Black */
          color: #1f1f25;
        }
        .viewdetail {
          cursor: pointer;

          /* item05 */

          box-sizing: border-box;
          background: #fff4f1;
          border-radius: 4px;
          padding: 6.5px 50.5px;

          font-family: Poppins-Medium !important;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 17px;
          /* identical to box height */

          color: #ee4b22;
        }
      }
      main {
        margin-top: 34px;
        width: 70%;
        transform: translateX(-80px);
        .barChart {
          height: 285px;
          width: 700px;
          overflow-y: auto;
          scrollbar-width: none;
          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
      footer {
        margin-top: 20px;
        .con {
          width: 100%;
          display: flex;
          flex-flow: row wrap;
          column-gap: 41px;
          row-gap: 19px;
          .item {
            flex-shrink: 0;
            display: flex;
            column-gap: 10.04px;
            .circle {
              /* Ellipse 159 */

              width: 12.62px;
              height: 12.62px;
              border-radius: 50%;
            }
            .txt {
              width: 150px;
              font-family: Poppins-Medium !important;
              font-style: normal;
              font-weight: 500;
              font-size: 13.6243px;
              line-height: 16px;
              color: #808080;
            }
          }
        }
      }
    }

    .childcon2 {
      margin-bottom: 32px;
      background-color: #ffffff;
      padding: 37px 34px 29px 32px;
      width: 100%;
      height: 512px;
      min-height: 600px;
      box-shadow: 0px 4px 10px rgba(69, 69, 80, 0.1);
      border-radius: 8px;

      header {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        .title {
          font-family: Poppins-SemiBold !important;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          /* identical to box height, or 120% */

          /* Neutral/Black */
          color: #1f1f25;
        }
        .viewdetail {
          cursor: pointer;
          font-family: Poppins-Medium !important;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: #ee4b22;
          /* view report */

          box-sizing: border-box;

          padding: 10px 12px;
          gap: 4px;
          border: 1px solid #ee4b22;
          border-radius: 4px;
        }
      }
      main {
        // display: flex;
        // gap: 42px;
        .donutchartout {
          transform: translateX(-40px);
        }
        .labelcon {
          max-height: 32px !important;
          width: 100%;
          margin-top: 28px;
          margin-right: 30px;
          gap: 11px 14px;
          .labelItem {
            flex-basis: 172px;
            margin-top: 11px;
          }
        }
      }
    }
    .borderdivider {
      border-bottom: 1px solid #d0d5dd;
      margin-bottom: 32px;
    }
  }
  .rightcon {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 20px;
    height: 765px;
    border-radius: 8px;
    .title {
      font-family: Poppins-SemiBold !important;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      /* identical to box height, or 120% */

      /* Neutral/Black */
      color: #1f1f25;
    }
    .view-all {
      font-size: 12px;
      color: #5f2ee5;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .childcon2 {
      margin-bottom: 32px;
      background-color: #ffffff;
      padding: 37px 34px 29px 32px;
      width: 100%;
      height: 512px;
      min-height: 600px;
      box-shadow: 0px 4px 10px rgba(69, 69, 80, 0.1);
      border-radius: 8px;

      header {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        .title {
          font-family: Poppins-SemiBold !important;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          /* identical to box height, or 120% */

          /* Neutral/Black */
          color: #1f1f25;
        }
        .viewdetail {
          cursor: pointer;
          font-family: Poppins-Medium !important;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: #ee4b22;
          /* view report */

          box-sizing: border-box;

          padding: 10px 12px;
          gap: 4px;
          border: 1px solid #ee4b22;
          border-radius: 4px;
        }
      }
      main {
        // display: flex;
        // gap: 42px;
        .donutchartout {
          transform: translateX(-40px);
        }
        .labelcon {
          max-height: 32px !important;
          width: 100%;
          margin-top: 28px;
          margin-right: 30px;
          display: flex;
          flex-flow: row wrap;
          gap: 11px 14px;
          .labelItem {
            flex-basis: 172px;
            margin-top: 11px;
          }
        }
      }
    }
    .borderdivider {
      border-bottom: 1px solid #d0d5dd;
      margin-bottom: 32px;
    }
  }
}
.Postioncon {
  margin-bottom: 32px;
  padding: 0px 54px 24px 24px;
  display: flex;
  border-bottom: 1px solid #d0d5dd;

  .leftcon {
    .childcon {
      margin-bottom: 32px;
      background-color: #ffffff;
      box-shadow: 0px 4px 10px 0px #4545501a;
      padding: 37px 34px 29px 32px;
      width: 100%;
      height: 645px;
      border-radius: 8px;
      header {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        .title {
          font-family: Poppins-SemiBold !important;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          /* identical to box height, or 120% */

          /* Neutral/Black */
          color: #1f1f25;
        }
        .viewdetail {
          cursor: pointer;

          /* item05 */

          box-sizing: border-box;
          background: #fff4f1;
          border-radius: 4px;
          padding: 6.5px 50.5px;

          font-family: Poppins-Medium !important;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 17px;
          /* identical to box height */

          color: #ee4b22;
        }
      }
      main {
        margin-top: 34px;
        width: 70%;
        transform: translateX(-80px);
        .barChart {
          height: 285px;
          width: 700px;
          overflow-y: auto;
          scrollbar-width: none;
          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
      footer {
        margin-top: 20px;
        .con {
          width: 100%;
          display: flex;
          flex-flow: row wrap;
          column-gap: 41px;
          row-gap: 19px;
          .item {
            flex-shrink: 0;
            display: flex;
            column-gap: 10.04px;
            .circle {
              /* Ellipse 159 */

              width: 12.62px;
              height: 12.62px;
              border-radius: 50%;
            }
            .txt {
              width: 150px;
              font-family: Poppins-Medium !important;
              font-style: normal;
              font-weight: 500;
              font-size: 13.6243px;
              line-height: 16px;
              color: #808080;
            }
          }
        }
      }
    }
    .view-all {
      font-size: 12px;
      color: #5f2ee5;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .childcon2 {
      margin-bottom: 32px;
      background-color: #ffffff;
      padding: 37px 34px 29px 32px;
      width: 100%;
      height: 512px;
      min-height: 600px;
      box-shadow: 0px 4px 10px rgba(69, 69, 80, 0.1);
      border-radius: 8px;

      header {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        .title {
          font-family: Poppins-SemiBold !important;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          /* identical to box height, or 120% */

          /* Neutral/Black */
          color: #1f1f25;
        }
        .viewdetail {
          cursor: pointer;
          font-family: Poppins-Medium !important;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: #ee4b22;
          /* view report */

          box-sizing: border-box;

          padding: 10px 12px;
          gap: 4px;
          border: 1px solid #ee4b22;
          border-radius: 4px;
        }
      }
      main {
        // display: flex;
        // gap: 42px;
        .donutchartout {
          transform: translateX(-40px);
        }
        .labelcon {
          max-height: 32px !important;
          width: 100%;
          margin-top: 28px;
          margin-right: 30px;
          gap: 11px 14px;
          .labelItem {
            flex-basis: 172px;
            margin-top: 11px;
          }
        }
      }
    }
    .borderdivider {
      border-bottom: 1px solid #d0d5dd;
      margin-bottom: 32px;
    }
  }
  .rightcon {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 20px;
    height: 765px;
    border-radius: 8px;
    .title {
      font-family: Poppins-SemiBold !important;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      /* identical to box height, or 120% */

      /* Neutral/Black */
      color: #1f1f25;
    }
    .view-all {
      font-size: 12px;
      color: #5f2ee5;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .childcon2 {
      margin-bottom: 32px;
      background-color: #ffffff;
      padding: 37px 34px 29px 32px;
      width: 100%;
      height: 512px;
      min-height: 600px;
      box-shadow: 0px 4px 10px rgba(69, 69, 80, 0.1);
      border-radius: 8px;

      header {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        .title {
          font-family: Poppins-SemiBold !important;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          /* identical to box height, or 120% */

          /* Neutral/Black */
          color: #1f1f25;
        }
        .viewdetail {
          cursor: pointer;
          font-family: Poppins-Medium !important;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: #ee4b22;
          /* view report */

          box-sizing: border-box;

          padding: 10px 12px;
          gap: 4px;
          border: 1px solid #ee4b22;
          border-radius: 4px;
        }
      }
      main {
        // display: flex;
        // gap: 42px;
        .donutchartout {
          transform: translateX(-40px);
        }
        .labelcon {
          max-height: 32px !important;
          width: 100%;
          margin-top: 28px;
          margin-right: 30px;
          display: flex;
          flex-flow: row wrap;
          gap: 11px 14px;
          .labelItem {
            flex-basis: 172px;
            margin-top: 11px;
          }
        }
      }
    }
    .borderdivider {
      border-bottom: 1px solid #d0d5dd;
      margin-bottom: 32px;
    }
  }
}
.openings-worked {
  &:hover {
    background-color: #f3e8ff !important;
    border: 1px solid #bf83ff !important;
    svg {
      path {
        fill: #fff !important;
      }
      g {
        fill: #bf83ff !important;
      }
    }
    .image {
      background-color: #bf83ff !important;
    }
    .description {
      color: #bf83ff !important;
    }
  }
}
.cv-sent {
  &:hover {
    background-color: #dcfce7 !important;
    border: 1px solid #3cd856 !important;
    svg {
      path {
        fill: #fff !important;
      }
      g {
        fill: #3cd856 !important;
      }
    }
    .image {
      background-color: #3cd856 !important;
    }
    .description {
      color: #3cd856 !important;
    }
  }
}
.cv-shortlisted {
  &:hover {
    background-color: #fff4de !important;
    border: 1px solid #ff947a !important;
    svg {
      path {
        fill: #fff !important;
      }
      g {
        fill: #ff947a !important;
      }
    }
    .image {
      background-color: #ff947a !important;
    }
    .description {
      color: #ff947a !important;
    }
  }
}
.Candidate-interviewed {
  &:hover {
    background-color: #ffe2e5 !important;
    border: 1px solid #fa5a7d !important;
    svg {
      path {
        fill: #fff !important;
      }
      g {
        fill: #fa5a7d !important;
      }
    }
    .image {
      background-color: #fa5a7d !important;
    }
    .description {
      color: #fa5a7d !important;
    }
  }
}
.candidate-joined {
  &:hover {
    background-color: #f7f2ed !important;
    border: 1px solid #c79767 !important;
    svg {
      path {
        fill: #fff !important;
      }
      g {
        fill: #c79767 !important;
      }
    }
    .image {
      background-color: #c79767 !important;
    }
    .description {
      color: #c79767 !important;
    }
  }
}
.billing-value {
  &:hover {
    background-color: #f3e8ff !important;
    border: 1px solid #bf83ff !important;
    svg {
      path {
        fill: #fff !important;
      }
      g {
        fill: #bf83ff !important;
      }
    }
    .image {
      background-color: #bf83ff !important;
    }
    .description {
      color: #bf83ff !important;
    }
  }
}
.feedback-awaited {
  &:hover {
    background-color: #ebfaf7 !important;
    border: 1px solid #33ccb2 !important;
    svg {
      path {
        fill: #fff !important;
      }
      g {
        fill: #33ccb2 !important;
      }
    }
    .image {
      background-color: #33ccb2 !important;
    }
    .description {
      color: #33ccb2 !important;
    }
  }
}
.cv-rejected {
  &:hover {
    background-color: #e5f9ff !important;
    border: 1px solid #33ccff !important;
    svg {
      path {
        fill: #fff !important;
      }
      g {
        fill: #33ccff !important;
      }
    }
    .image {
      background-color: #33ccff !important;
    }
    .description {
      color: #33ccff !important;
    }
  }
}
.Candidate-interview-rejected {
  &:hover {
    background-color: #e5e5ff !important;
    border: 1px solid #5c5cff !important;
    svg {
      path {
        fill: #fff !important;
      }
      g {
        fill: #5c5cff !important;
      }
    }
    .image {
      background-color: #5c5cff !important;
    }
    .description {
      color: #5c5cff !important;
    }
  }
}
.candidate-offered {
  &:hover {
    background-color: #f4e9fb !important;
    border: 1px solid #a752e0 !important;
    svg {
      path {
        fill: #fff !important;
      }
      g {
        fill: #a752e0 !important;
      }
    }
    .image {
      background-color: #a752e0 !important;
    }
    .description {
      color: #a752e0 !important;
    }
  }
}
.offer-rejected {
  &:hover {
    background-color: #fce8ec !important;
    border: 1px solid #e94967 !important;
    svg {
      path {
        fill: #fff !important;
      }
      g {
        fill: #e94967 !important;
      }
    }
    .image {
      background-color: #e94967 !important;
    }
    .description {
      color: #e94967 !important;
    }
  }
}
.openings-billed {
  &:hover {
    background-color: #efecfb !important;
    border: 1px solid #5e45e5 !important;
    svg {
      path {
        fill: #fff !important;
      }
      g {
        fill: #5e45e5 !important;
      }
    }
    .image {
      background-color: #5e45e5 !important;
    }
    .description {
      color: #5e45e5 !important;
    }
  }
}
.Alpha {
  &:hover {
    background-color: #fffaef !important;
    .month {
      background-color: #f5ce80 !important;
    }
    .price {
      color: #f5ce80 !important;
    }

    .currency {
      svg {
        path {
          fill: #f5ce80 !important;
        }
      }
    }
  }
}
.Bravo {
  &:hover {
    background-color: #ebfaf7 !important;
    .month {
      background-color: #b2f5e8 !important;
    }
    .price {
      color: #b2f5e8 !important;
    }

    .currency {
      svg {
        path {
          fill: #b2f5e8 !important;
        }
      }
    }
  }
}
.Charlie {
  &:hover {
    background-color: #f8ecff !important;
    .month {
      background-color: #dfbef4 !important;
    }
    .price {
      color: #dfbef4 !important;
    }

    .currency {
      svg {
        path {
          fill: #dfbef4 !important;
        }
      }
    }
  }
}
.Calender {
  // display: flex;
  min-height: 400px !important;
  font-family: Poppins-Medium !important;
  font-size: 14px;
  // margin-top: 20px;
  .f .fc-header-toolbar {
    background-color: #fff;
    color: #344051;
  }
  .fc .fc-daygrid-day.fc-day-today {
    color: #ee4b22 !important; /* Text color */
    background-color: unset !important;
  }
  .fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number{
    border-radius: 50%;
    border: 2px solid;
  }
  .fc .fc-button-primary {
    background-color: transparent;
    border: none;
    color: #344051;
  }
  thead {
    background-color: #fff4f1;
    height: 50px;
  }
  .fc .fc-toolbar-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 24.63px;
    letter-spacing: 0.3899476230144501px;
    text-align: left;
  }
  .fc .fc-view-harness {
    height: 345.741px !important;
  }
  .calendar-sidebar {
    width: auto;
    line-height: 1.5;
    background: #fff;
    border-right: 1px solid #d3e2e8;
    border-bottom: 1px solid #d3e2e8;
  }
  .fc .fc-button.fc-prev-button,
  .fc .fc-button.fc-next-button {
    :focus-visible {
      color: #ee4b22 !important;
    }
    color: #ee4b22 !important;
  }
  .fc .fc-button.fc-prev-button:hover,
  .fc .fc-button.fc-next-button:hover {
    background-color: #ee4b22;
    color: #ee4b22 !important; /* optional for hover effect */
  }

  .fc-toolbar.fc-header-toolbar {
    height: 50px;
    background-color: #fdede9;
    margin-bottom: 0px;
    border-radius: 10px 10px 0px 0px;
  }
  .fc .fc-col-header-cell-cushion {
    margin-top: 12px;
    font-family: Poppins;
    font-size: 12.31px;
    font-weight: 600;
    line-height: 16.42px;
    text-align: center;
  }
  .fc-toolbar {
    .fc-toolbar-chunk {
      div {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
      }
    }
  }
  .fc-button {
    text-transform: capitalize !important;
  }
  .fc .fc-button-primary:focus {
    background-color: transparent !important;
    color: #344051 !important;
    box-shadow: none !important;
  }
  .fc .fc-daygrid-day-frame {
    padding: 0px 3px 0px 0px;
    border: none;
    background: transparent;
  }
  .fc-h-event .fc-event-title-container {
    text-transform: capitalize;
  }
  .fc-theme-standard th {
    border: none !important;
  }
  .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }
  .fc-theme-standard td {
    border: none !important;
  }
  .fc .fc-daygrid-day.fc-day-today {
    border: none;
    background-color: unset !important;
  }
  .fc .fc-scrollgrid {
    margin-top: 4px !important;
  }
  .fc .fc-button-group > .fc-button.fc-button-active {
    border-radius: 0px;
    background-color: transparent;
    color: #344051;
    border-bottom: 1px solid #344051 !important;
    outline: none;
    border: none;
    box-shadow: none !important;
  }
  .fc .fc-scroller-liquid-absolute {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
  }
  .fc .fc-scroller {
    overflow: hidden !important;
  }
  .fc-prev-button,
  .fc-today-button,
  .fc-next-button {
    background-color: transparent !important;
    color: #344051;
    border: none;
  }
  .fc .fc-button-primary:disabled {
    background-color: transparent !important;
    color: #344051;
  }
}
.dot-marker {
  // color: #ee4b22 !important; /* Set the color of the dot */
  font-size: 20px; /* Adjust dot size */
  line-height: 0.5; /* Adjust to align dot in the middle */
  text-align: center;
}
