.materialui-daterange-picker-makeStyles-dateRangePickerContainer-1 {
  position: absolute !important;
}

.custom-date-input {
  height: 40px;
  width: 220px !important;
  padding: 0 10px;
  border: 1px solid #f4511e; /* Default border color */
  border-radius: 4px;
  font-size: 14px;
  outline: none;
  transition: border-color 0.3s ease;

  &:hover,
  &:focus {
    border-color: #f4511e; /* Change border to red on hover or focus */
  }

  &::placeholder {
    color: #bdbdbd; /* Set the color */
    margin-left: 2px;
    margin-right: 2px;
    box-sizing: border-box;
    max-width: 100%;
    overflow: hidden;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.custom-date-picker-wrapper {
  position: relative;
  .custom-date-input1 {
    width: 100%;

    .MuiOutlinedInput-notchedOutline {
      border: unset !important;
    }

    .MuiInputBase-root {
      min-height: 40px !important;
      border-radius: 6px !important;
      border: 1px solid #f4511e;
      background: #fff;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

      &.Mui-error {
        border: 1px solid red !important;
      }

      &::before,
      &::after {
        border-bottom: unset;
      }
    }

    p {
      &.MuiFormHelperText-root {
        color: red !important;
        margin: 4px 0px 0px 0px !important;
        font-size: 12px !important;
      }

      &.Mui-error {
        color: red !important;
        margin: 4px 0px 0px 0px !important;
        font-size: 12px !important;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border: unset !important;
    }

    .MuiInputBase-root {
      min-height: 40px !important;
      border-radius: 6px !important;
      border: 1px solid #f4511e;
      background: #fff;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

      &::before,
      &::after {
        border-bottom: unset;
      }
    }

    .MuiInputBase-input {
      padding: 7px 7px !important;
      font-size: 14px !important;
      font-family: poppins-Regular;

      &::placeholder {
        color: #bdbdbd;
        font-size: 14px !important;
        font-family: Poppins-Medium !important;
      }
    }
  }
}

.input-with-icon {
  position: relative;
  display: flex;
  align-items: center;
}

.custom-date-input {
  padding-right: 30px; // Extra padding to accommodate the clear icon
  width: 100%;
}

.clear-icon {
  height: 20px !important;
  width: 20px !important;
  margin-bottom: 2px;
  position: absolute;
  right: 10px;
  cursor: pointer;
  color: #aaa;
  &:hover {
    color: #333;
  }
}
